
const Footers = () => {

    return (
        <div className="bg-black p-4 text-center fixed w-full z-0 bottom-0">
            <div className="inline-flex -mt-80 text-white text-center gap-5" style={{ fontSize: "13px" }}>
                <p>&copy;{new Date().getFullYear()} ECOREME TECHNOLOGIES</p>
            </div>
        </div>


    )
}
export default Footers

